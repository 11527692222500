import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';
import OwnerStorySkeleton from 'dpl/components/OwnerStory/OwnerStorySkeleton';
import { makeSequence } from 'dpl/shared/utils';
import { isGreaterThanBreakpoint } from 'dpl/util/grid';

import { STORIES_PER_PAGE } from '../BreederProfileTestimonialsSection';

const IS_GT_MD = isGreaterThanBreakpoint('md');

export default function OwnerStoriesSkeleton({ className }) {
  return (
    <div
      className={classnames(
        'OwnerStoriesSkeleton container-xxl pv12 pv20-md',
        className
      )}
    >
      <div className="mb10 mb12-md">
        <SkeletonBlock
          className="font-24 md:font-40 mb2"
          height="1em"
          width="12em"
        />
        <SkeletonBlock className="font-18" height="1em" width="40em" />
      </div>
      <ul className="grid lg:grid-cols-2 gap-80">
        {makeSequence(STORIES_PER_PAGE).map(index => (
          <li
            key={index}
            className={classnames({
              'bt b--default pt8 pt12-md': index > (IS_GT_MD ? 1 : 0)
            })}
          >
            <OwnerStorySkeleton />
          </li>
        ))}
      </ul>
    </div>
  );
}

OwnerStoriesSkeleton.propTypes = {
  className: PropTypes.string
};

OwnerStoriesSkeleton.defaultProps = {
  className: null
};
