import React, { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose } from 'redux';

import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_USER_JOURNEYS,
  EVENT_CATEGORIES,
  EVENT_ACTION_IDS,
  ANALYTICS_VIEWS
} from 'dpl/constants/analytics';
import {
  sendRudderstackEvent,
  trackEvent,
  trackConversion
} from 'dpl/util/analytics';
import BreederProfileQuestionModal from 'dpl/components/BreederProfile/Show/BreederProfileQuestionModal';
import Icon from 'dpl/common/components/Icon';

export default function BreederProfileQuestionButton({
  className,
  withIcon,
  analytics,
  breederProfileId
}) {
  const profileId =
    useSelector(({ server }) => server.breederProfileId) || breederProfileId;
  const [questionModalIsOpen, setQuestionModalIsOpen] = useState(false);

  const currentUser = useCurrentUser();

  const handleClick = compose(
    trackEvent(EVENT_CATEGORIES.ASK_BREEDER_QUESTION),
    trackConversion(EVENT_ACTION_IDS.ASK_BREEDER_QUESTION)
  )(() => {
    sendRudderstackEvent(ANALYTICS_EVENTS.BUTTON_CLICKED, {
      button_cta: 'Ask a Question',
      buyer_profile_id: currentUser?.value?.data?.buyer_profile_id,
      breeder_profile_id: profileId,
      user_journey: ANALYTICS_USER_JOURNEYS.BUYER_BROWSING,
      view: ANALYTICS_VIEWS.BREEDER_PROFILE,
      ...analytics
    });
    setQuestionModalIsOpen(true);
  });

  function handleClose() {
    setQuestionModalIsOpen(false);
  }

  return (
    <Fragment>
      <button
        type="button"
        className={classnames('BreederProfileQuestionButton', className)}
        onClick={handleClick}
      >
        {withIcon && <Icon className="mr3" name="message" />}
        Ask a question
      </button>
      {questionModalIsOpen && (
        <BreederProfileQuestionModal
          breederProfileId={profileId}
          onClose={handleClose}
        />
      )}
    </Fragment>
  );
}

BreederProfileQuestionButton.propTypes = {
  className: PropTypes.string,
  withIcon: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  analytics: PropTypes.object,
  breederProfileId: PropTypes.number
};

BreederProfileQuestionButton.defaultProps = {
  className:
    'ph6 pv2 ba bw1 b--purple br1 f2 fw-medium button button--secondary',
  withIcon: false,
  analytics: null,
  breederProfileId: null
};
