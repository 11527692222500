import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { breederProfileDogsResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import { GENDERS } from 'dpl/shared/constants/breeder_app';
import { groupBy, uniqBy } from 'dpl/shared/utils';
import SmartImage from 'dpl/components/SmartImage';
import Icon from 'dpl/common/components/Icon';
import ImagePlaceholder from 'dpl/components/MediaGrid/ImagePlaceholder';
import ParentHealthTestingBanner from 'dpl/common/components/ParentHealthTestingBanner';
import BreederProfileBreedFilter from 'dpl/components/BreederProfile/Show/BreederProfileBreedFilter';
import ClampedMultiLineText from 'dpl/common/components/ClampedMultiLineText';
import BreederProfileParentsSkeleton from 'dpl/components/BreederProfile/Show/skeletons/BreederProfileParentsSkeleton';

import allBreedsPhoto from 'dpl/assets/favorites_all_breeds.jpg';

const SPLIDE_OPTIONS = {
  arrows: false,
  dragMinThreshold: 20,
  flickMaxPages: 1,
  flickPower: 300,
  gap: 16,
  keyboard: 'focused',
  pagination: false,
  perPage: 1,
  snap: true
};

function ParentDogSlide({ dog }) {
  const parentTitle = dog.gender === GENDERS.FEMALE ? 'mom' : 'dad';

  return (
    <SplideSlide key={dog.id} className="parent-dog-slide">
      <div className="ParentDog bg-white br4 pv4 pl4 pr4 pr12-md font-16 h-100">
        <div className="row wide-gutters items-center h-100-md">
          <div className="col-md-5">
            <div className="aspect-ratio--4x3 relative">
              <div className="aspect-ratio--object">
                {dog.profile_photo_url ? (
                  <SmartImage
                    Tag="div"
                    className="br3 w-100 h-100 cover bg-center"
                    src={dog.profile_photo_url}
                  />
                ) : (
                  <ImagePlaceholder className="br3" />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <h4 className="font-20 md:font-32 mt6 mt0-md mb2 lh-title">
              {dog.name}, {parentTitle}
            </h4>
            <p className="secondary">{dog.breed_name}</p>
            <div className="mt6 mt8-md">
              <p className="fw-medium mb3">About {dog.name}</p>
              <ClampedMultiLineText
                className="secondary lh-body"
                numOfLines={5}
                lineHeight={22.4}
                text={dog.description}
                moreText="Read more"
                lessText="Show less"
                moreButtonClassName="stone-700"
                fadeToButton
              />
            </div>
            {dog.health_testing && (
              <ParentHealthTestingBanner
                className="bg-subtle-light-gray w-100 w-80-md mt6 mt8-md"
                healthTesting={dog.health_testing}
                parentId={dog.id}
              />
            )}
          </div>
        </div>
      </div>
    </SplideSlide>
  );
}

ParentDogSlide.propTypes = {
  dog: PropTypes.shape({
    breed_name: PropTypes.string,
    description: PropTypes.string,
    gender: PropTypes.oneOf(Object.values(GENDERS)),
    health_testing: PropTypes.shape({
      title: PropTypes.string,
      documented_health_tests: PropTypes.arrayOf(PropTypes.string)
    }),
    id: PropTypes.number,
    name: PropTypes.string,
    profile_photo_url: PropTypes.string
  }).isRequired
};

export default function BreederProfileParentsSectionContainer() {
  const { breederFirstName, breederProfileId } = useSelector(
    ({ server }) => server
  );
  const [selectedFilterIdx, setSelectedFilterIdx] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const splideRef = useRef(null);

  const {
    isFetching,
    value: {
      data: { dogs }
    }
  } = useResourceQuery(breederProfileDogsResourceDefinition(breederProfileId));

  if (isFetching) {
    return <BreederProfileParentsSkeleton />;
  }

  if (dogs.length === 0) {
    return null;
  }

  const dogsByBreed = groupBy(dogs, 'breed_name');

  const parentDogs =
    selectedFilterIdx === 0
      ? dogs
      : dogsByBreed[Object.keys(dogsByBreed)[selectedFilterIdx - 1]];

  const dogCount = parentDogs.length;

  const breeds = uniqBy(dogs, 'breed_name').map(dog => {
    return {
      label: dog.breed_name,
      photo_url: dog.breed_cover_photo
    };
  });

  const breedFilters = [
    { label: 'All breeds', photo_url: allBreedsPhoto },
    ...breeds
  ];

  const isNextButtonDisabled = activeIndex >= dogCount - 1;
  const isPrevButtonDisabled = activeIndex <= 0;

  function handleBreedClick(idx) {
    setSelectedFilterIdx(idx);
  }

  function handleActiveSlide(slide) {
    setActiveIndex(slide.index);
  }

  return (
    <section className="BreederProfileParentsSection bg-subtle-light-gray pv12 pv20-md">
      <div className="container-xxl">
        <h2 className="mb8 font-24 md:font-40 lh-title">
          Meet {breederFirstName}&apos;s parent dogs
        </h2>
        <BreederProfileBreedFilter
          className="mb6 mb14-md"
          items={breedFilters}
          onFilterClick={handleBreedClick}
          selectedFilterIdx={selectedFilterIdx}
        />
        <Splide
          options={{
            ...SPLIDE_OPTIONS,
            drag: dogCount > 0,
            start: activeIndex
          }}
          ref={splideRef}
          renderControls={null}
          hasSliderWrapper={false}
          onActive={handleActiveSlide}
        >
          {parentDogs.map((dog, idx) => (
            <ParentDogSlide key={idx} dog={dog} />
          ))}
        </Splide>
        {dogCount > 1 && (
          <div className="mt3 mt7-md secondary">
            <button
              type="button"
              className={classnames('bg-white ba b--default br-100 mr4', {
                'o-60': isPrevButtonDisabled
              })}
              disabled={isPrevButtonDisabled}
              onClick={() => splideRef.current.go('<')}
            >
              <div className="flex items-center justify-center ph3 pv3">
                <Icon name="fetch-arrow-left" />
              </div>
            </button>
            <button
              type="button"
              className={classnames('bg-white ba b--default br-100', {
                'o-60': isNextButtonDisabled
              })}
              disabled={isNextButtonDisabled}
              onClick={() => splideRef.current.go('>')}
            >
              <div className="flex items-center justify-center ph3 pv3">
                <Icon name="fetch-arrow-right" />
              </div>
            </button>
          </div>
        )}
      </div>
    </section>
  );
}
