import classnames from 'classnames';
import React, { useRef, useState } from 'react';

import { BUTTON_VARIANTS } from 'dpl/common/design_system/Button';
import {
  ANALYTICS_VIEW_FEATURE_LOCATIONS,
  ANALYTICS_VIEW_FEATURES,
  ANALYTICS_VIEWS
} from 'dpl/constants/analytics';
import { isLessThanBreakpoint } from 'dpl/util/grid';

import BreederProfileApplyButtonContainer from '../BreederProfileApplyButtonContainer';
import BreederProfileFollowButtonContainer from '../BreederProfileFollowButtonContainer';

const IS_LT_MD = isLessThanBreakpoint('md');

const COMMON_ANALYTICS_PROPS = {
  view: ANALYTICS_VIEWS.BREEDER_PROFILE,
  view_feature_location:
    ANALYTICS_VIEW_FEATURE_LOCATIONS.BREEDER_PROFILE_STICKY_BOTTOM
};

export default function BreederProfileMobileActionsContainer() {
  const ref = useRef();
  const [hasFollowButton, setHasFollowButton] = useState(null);
  const [hasApplyButton, setHasApplyButton] = useState(null);

  const isReady = hasFollowButton !== null && hasApplyButton !== null;
  const hasButtons = isReady && (hasFollowButton || hasApplyButton);

  if (!IS_LT_MD) return null;

  return (
    <div
      ref={ref}
      className={classnames(
        'BreederProfileMobileActionsContainer grid transition fixed md:hidden bottom-0 bg-white w-100 bt b--default pv4 ph4 z-999',
        {
          'o-0': !hasButtons,
          'o-100': hasButtons,
          'grid-cols-2 gap-8': hasFollowButton && hasApplyButton
        }
      )}
    >
      <BreederProfileFollowButtonContainer
        parentElement={ref.current}
        className={classnames(BUTTON_VARIANTS.outline, 'font-16 w-100 pv3')}
        onLoad={({ rendered }) => setHasFollowButton(rendered)}
        analytics={COMMON_ANALYTICS_PROPS}
      />
      <BreederProfileApplyButtonContainer
        className={classnames(BUTTON_VARIANTS.primary, 'font-16 w-100 pv3')}
        onLoad={({ rendered }) => setHasApplyButton(rendered)}
        analytics={{
          ...COMMON_ANALYTICS_PROPS,
          view_feature: ANALYTICS_VIEW_FEATURES.APPLY_TO_BREEDER_BUTTON
        }}
      />
    </div>
  );
}
