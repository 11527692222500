import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'validate.js';

import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import LoadingDots from 'dpl/components/LoadingDots';
import {
  ANALYTICS_VIEW_FEATURE_LOCATIONS,
  ANALYTICS_VIEWS
} from 'dpl/constants/analytics';
import { BREED_ID } from 'dpl/constants/query_params';
import FavoriteButtonV2 from 'dpl/favorites/components/FavoriteButtonV2';
import useUserFollowsData from 'dpl/favorites/hooks/useUserFollowsData';
import { FOLLOWABLE_TYPES } from 'dpl/favorites/utils/constants';

const ICON_DIMENSIONS = { height: '20px', width: '20px' };

const DEFAULT_ANALYTICS = {
  view: ANALYTICS_VIEWS.BREEDER_PROFILE,
  view_feature_location: ANALYTICS_VIEW_FEATURE_LOCATIONS.BREEDER_PROFILE_HEADER
};

export default function BreederProfileFollowButtonContainer({
  className,
  onLoad,
  parentElement,
  analytics
}) {
  const { isBreeder } = useCurrentUser();

  const { breedId, breederBreeds } = useSelector(({ queryParams, server }) => ({
    breedId: queryParams[BREED_ID],
    breederBreeds: server.breederBreeds
  }));

  const breederBreed =
    breederBreeds.find(bb => bb.breed_id === Number(breedId)) ?? {};

  const { hasUserFollow: userFollow, isLoading } = useUserFollowsData(
    breederBreed.id,
    FOLLOWABLE_TYPES.BREEDER_BREED
  );

  const isFollowing = Boolean(userFollow);
  const shouldRender = !isBreeder && !isEmpty(breederBreed);

  useEffect(() => {
    if (!isLoading) {
      onLoad?.({ rendered: shouldRender });
    }
  }, [isLoading, shouldRender]);

  if (!shouldRender) return null;

  if (isLoading) {
    return (
      <div className={className}>
        <LoadingDots height="20px" isVisible />
      </div>
    );
  }

  return (
    <FavoriteButtonV2
      className={className}
      isFavorite={isFollowing}
      followableTypeId={breederBreed.id}
      followableType={FOLLOWABLE_TYPES.BREEDER_BREED}
      targetEl={parentElement}
      portalStyle={{ top: '-50%' }}
      checkedIconProps={{
        ...ICON_DIMENSIONS,
        name: 'fetch-favorite',
        className: 'red',
        label: 'Following'
      }}
      uncheckedIconProps={{
        ...ICON_DIMENSIONS,
        name: 'fetch-favorite-outline',
        label: 'Follow'
      }}
      analytics={analytics}
    />
  );
}

BreederProfileFollowButtonContainer.propTypes = {
  className: PropTypes.string,
  onLoad: PropTypes.func,
  parentElement: PropTypes.instanceOf(window.HTMLElement),
  // eslint-disable-next-line react/forbid-prop-types
  analytics: PropTypes.object
};

BreederProfileFollowButtonContainer.defaultProps = {
  className: 'font-16 fw-medium stone-700 ba b--default br-pill pv2 ph3',
  onLoad: null,
  parentElement: undefined,
  analytics: DEFAULT_ANALYTICS
};
