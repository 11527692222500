import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { BREED_ID } from 'dpl/constants/query_params';
import { singleBreederProfileResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import __DEPRECATED_defineResources from 'dpl/shared/fetching/deprecated/defineResources';
import BreederProfileApplyButton from 'dpl/components/BreederProfile/Show/BreederProfileApplyButton';
import usePuppiesAndLitterAvailabilityCheck from 'dpl/adoption_application/hooks/usePuppiesAndLitterAvailabilityCheck';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import { ANALYTICS_VIEWS } from 'dpl/constants/analytics';

export default function BreederProfileApplyButtonContainer({
  onLoad,
  ...props
}) {
  const { breederProfileId, breederPreferredStatus } = useSelector(
    ({ server }) => server
  );
  const breedId = useSelector(({ queryParams }) => queryParams[BREED_ID]);

  const { availablePuppiesForBreed } =
    usePuppiesAndLitterAvailabilityCheck(breedId);

  const currentUser = useCurrentUser();

  const {
    isFetching,
    isSuccess,
    value: breederProfileShowData
  } = useResourceQuery({
    ...singleBreederProfileResourceDefinition(parseInt(breederProfileId, 10)),
    enabled: Boolean(breederProfileId)
  });

  const unsnoozedBreeds = breederProfileShowData.breeds.filter(b => !b.snoozed);

  const shouldShow =
    isSuccess && unsnoozedBreeds.length > 0 && availablePuppiesForBreed;

  function onClick() {
    window.location = `${breederProfileShowData.profile_url}/contacts/buyer_application?view=${ANALYTICS_VIEWS.BREEDER_PROFILE}`;
  }

  useEffect(() => {
    if (!isFetching) {
      onLoad?.({ rendered: shouldShow });
    }
  }, [isFetching, shouldShow]);

  if (isFetching || !shouldShow) {
    return null;
  }

  return (
    <BreederProfileApplyButton
      {...props}
      breederProfileId={parseInt(breederProfileId, 10)}
      breederPreferredStatus={breederPreferredStatus}
      currentUser={currentUser}
      isLoggedIn={currentUser.isLoggedIn}
      onClick={onClick}
    />
  );
}

BreederProfileApplyButtonContainer.propTypes = {
  onLoad: PropTypes.func
};

BreederProfileApplyButtonContainer.defaultProps = {
  onLoad: null
};
