import React from 'react';

import BreederProfileTestimonialsSection from 'dpl/components/BreederProfile/Show/BreederProfileTestimonialsSection';
import BreederProfileAboutPuppiesSectionContainer from 'dpl/containers/BreederProfile/Show/BreederProfileAboutPuppiesSectionContainer';
import BreederProfileAdoptionProcessSectionContainer from 'dpl/containers/BreederProfile/Show/BreederProfileAdoptionProcessSectionContainer';
import BreederProfileCurrentPuppiesSectionContainer from 'dpl/containers/BreederProfile/Show/BreederProfileCurrentPuppiesSectionContainer';
import BreederProfileDeliverySectionContainer from 'dpl/containers/BreederProfile/Show/BreederProfileDeliverySectionContainer';
import BreederProfileHealthTestsSectionContainer from 'dpl/containers/BreederProfile/Show/BreederProfileHealthTestsSectionContainer';
import BreederProfileLittersSectionContainer from 'dpl/containers/BreederProfile/Show/BreederProfileLittersSectionContainer';
import BreederProfileMoreAboutBreederSectionContainer from 'dpl/containers/BreederProfile/Show/BreederProfileMoreAboutBreederSectionContainer';
import BreederProfileParentsSectionContainer from 'dpl/containers/BreederProfile/Show/BreederProfileParentsSectionContainer';
import BreederProfilePhotosSectionContainer from 'dpl/containers/BreederProfile/Show/BreederProfilePhotosSectionContainer';
import BreederProfileRelatedBreedsContainer from 'dpl/containers/BreederProfile/Show/BreederProfileRelatedBreedsContainer';

export default function BreederProfileLayoutContainer() {
  return (
    <div className="BreederProfileLayoutContainer">
      <BreederProfileCurrentPuppiesSectionContainer />
      <BreederProfileLittersSectionContainer />
      <BreederProfileAdoptionProcessSectionContainer />
      <BreederProfileAboutPuppiesSectionContainer />
      <BreederProfileDeliverySectionContainer />
      <BreederProfileMoreAboutBreederSectionContainer />
      <BreederProfileParentsSectionContainer />
      <BreederProfileHealthTestsSectionContainer />
      <BreederProfileTestimonialsSection />
      <BreederProfilePhotosSectionContainer />
      <BreederProfileRelatedBreedsContainer />
    </div>
  );
}
