import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { isEmpty } from 'validate.js';

import Button from 'dpl/common/design_system/Button';
import OwnerStory from 'dpl/components/OwnerStory';
import { breederProfileBreederTestimonialIndexResourceDefinition } from 'dpl/shared/breeder/utils/queryDefinitions';
import useInfiniteCollectionQuery from 'dpl/shared/fetching/hooks/useInfiniteCollectionQuery';
import { sendPageview } from 'dpl/util/analytics';
import { isGreaterThanBreakpoint } from 'dpl/util/grid';

import OwnerStoriesSkeleton from '../skeletons/BreederProfileOwnerStoriesSkeleton';

export const SECTION_ID = 'stories-section';
export const STORIES_PER_PAGE = 6;

export default function BreederProfileTestimonialsSection({ className }) {
  const { breederFirstName, breederProfileId, analyticsPageName } = useSelector(
    ({ server }) => server
  );

  const {
    infiniteScrollItems,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetching
  } = useInfiniteCollectionQuery(
    breederProfileBreederTestimonialIndexResourceDefinition(breederProfileId, {
      pageNumber: 1,
      perPage: STORIES_PER_PAGE
    })
  );

  const [ref] = useInView({
    triggerOnce: true,
    threshold: 0.75,
    onChange: inView => {
      if (!inView) return;

      sendPageview(
        {
          page_title: document.title,
          page_path: `${window.location.pathname}${window.location.search}`,
          page_location: window.location.href
        },
        {
          name: analyticsPageName,
          category: 'breeder-profile-owner-stories'
        }
      );
    }
  });

  const columns = useMemo(() => {
    return infiniteScrollItems.reduce(
      (acc, story, index) => {
        const key = isGreaterThanBreakpoint('md') ? index % 2 : 0;
        acc[key].push(story);
        return acc;
      },
      [[], []]
    );
  }, [infiniteScrollItems]);

  if (isLoading) {
    return <OwnerStoriesSkeleton className={className} />;
  }

  if (!isLoading && infiniteScrollItems.length === 0) {
    return null;
  }

  return (
    <section
      className={classnames(
        'BreederProfileTestimonialsSection container-xxl pv12 pv20-md',
        className
      )}
      id={SECTION_ID}
      ref={ref}
    >
      <div className="mb10 mb12-md">
        <h2 className="font-24 md:font-40 lh-title mb2">
          Verified owner stories
        </h2>
        <p className="font-18 secondary">
          Hear what other owners say about working with {breederFirstName} to
          bring home their puppies.
        </p>
      </div>
      <ul className="grid lg:grid-cols-2 justify-between gap-80">
        {columns
          .filter(column => !isEmpty(column))
          .map((stories, colIndex) => (
            <li key={colIndex}>
              {stories.map((story, index) => (
                <OwnerStory
                  key={story.id}
                  className={classnames({
                    'pb6 pb12-md': index !== stories.length - 1,
                    'pt6 pt12-md bt b--default': index
                  })}
                  date={story.formatted_submitted_at}
                  description={story.experience}
                  gallery={story.photographs}
                  user={story.buyer_profile}
                />
              ))}
            </li>
          ))}
      </ul>
      {hasNextPage && (
        <Button
          className="font-16 stone-700 ph16 pv3 w-100 w-auto-m"
          variant="outline"
          onClick={fetchNextPage}
          disabled={isFetching}
        >
          Show more owner stories
        </Button>
      )}
    </section>
  );
}

BreederProfileTestimonialsSection.propTypes = {
  className: PropTypes.string
};

BreederProfileTestimonialsSection.defaultProps = {
  className: null
};
