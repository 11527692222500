import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import PreferredBreederBadge from 'dpl/breeder_preferred_qualifications/components/PreferredBreederBadge';
import { STATUS } from 'dpl/breeder_preferred_qualifications/utils/constants';
import Icon from 'dpl/common/components/Icon';
import BreederProfileTrustedCardSkeleton from 'dpl/components/BreederProfile/Show/skeletons/BreederProfileTrustedCardSkeleton';
import UserAvatar from 'dpl/path_to_payment/components/UserAvatar';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { singleBreederProfileResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';

const TRUSTED_AREAS = [
  'Responsible breeding practices',
  'Health of breeding dogs and puppies',
  'Puppy environment and enrichment',
  'Buyer education and policies'
];

export default function BreederProfileTrustedCardContainer({ className }) {
  const { breederProfileId, breederPreferredStatus } = useSelector(
    ({ server }) => server
  );

  const { value: breeder, isFetching: isFetchingBreeder } = useResourceQuery(
    singleBreederProfileResourceDefinition(breederProfileId)
  );

  const isPreferredBreeder = breederPreferredStatus === STATUS.PREFERRED;

  if (isFetchingBreeder) {
    return <BreederProfileTrustedCardSkeleton />;
  }

  return (
    <div
      className={classnames(
        'BreederProfileTrustedCardContainer bg-blue-100 pv8 ph4 pv10-md ph10-md br3',
        className
      )}
    >
      <div className="dib relative mb6">
        <UserAvatar
          size="lg"
          user={{
            id: breeder.user.id,
            initials: breeder.initials,
            profile_photo_url: breeder.profile_photo_url
          }}
        />
        {isPreferredBreeder && (
          <PreferredBreederBadge
            className="absolute right-0 bottom-0"
            width="20px"
            height="22px"
            hideTooltip
          />
        )}
      </div>
      <div className="stone-700">
        <h6 className="font-24 md:font-32">
          {breeder.user.first_name} is a trusted Good Dog breeder
        </h6>
        <p className="font-16 md:font-18 fw-medium mt5">
          {breeder.name} meets or exceeds our community standards in these
          areas:
        </p>
      </div>
      <ul className="flex flex-column gap-16 midnight mt5">
        {TRUSTED_AREAS.map(area => (
          <li key={area}>
            <div className="flex gap-8 items-center">
              <div
                className="bg-blue-200 br-100 pv1 ph1"
                style={{ height: 24, width: 24 }}
              >
                <Icon name="checkmark" height="100%" width="100%" />
              </div>
              <span className="font-16 md:font-18">{area}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

BreederProfileTrustedCardContainer.propTypes = {
  className: PropTypes.string
};

BreederProfileTrustedCardContainer.defaultProps = {
  className: null
};
