import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { compose } from 'redux';

import Icon from 'dpl/common/components/Icon';
import {
  EVENT_CATEGORIES,
  EVENT_ACTION_IDS,
  ANALYTICS_VIEW_FEATURES,
  ANALYTICS_VIEWS,
  ANALYTICS_VIEW_FEATURE_LOCATIONS
} from 'dpl/constants/analytics';
import { get } from 'dpl/shared/utils/object';
import { setAnalyticsOrigin } from 'dpl/shared/utils/setAnalyticsOrigin';
import { trackEvent, trackConversion } from 'dpl/util/analytics';

export default class BreederProfileApplyButton extends Component {
  static propTypes = {
    breederProfileId: PropTypes.number.isRequired,
    className: PropTypes.string,
    currentUser: PropTypes.shape({
      value: PropTypes.shape({
        data: PropTypes.shape({
          breeder_profiles_applied_to: PropTypes.arrayOf(PropTypes.number),
          buyer_profile_id: PropTypes.number
        })
      })
    }).isRequired,
    isLoggedIn: PropTypes.bool,
    truncateText: PropTypes.bool,
    withIcon: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    analytics: PropTypes.shape({
      view: PropTypes.string,
      view_feature: PropTypes.string,
      view_feature_location: PropTypes.string
    }),
    customCopy: PropTypes.string
  };

  static defaultProps = {
    className:
      'ph6 pv2 ba bw1 b--purple br1 f2 fw-medium button button--primary',
    isLoggedIn: null,
    truncateText: false,
    withIcon: false,
    analytics: {
      view: ANALYTICS_VIEWS.BREEDER_PROFILE_PAGE,
      // We use this from a erb file so we are putting this here as a generic location
      view_feature_location: ANALYTICS_VIEW_FEATURE_LOCATIONS.CTA
    },
    customCopy: null
  };

  handleApplyClick = compose(
    trackEvent(EVENT_CATEGORIES.APPLY_TO_BREEDER),
    trackConversion(EVENT_ACTION_IDS.APPLY_TO_BREEDER)
  )(() => {
    const viewFeature =
      this.props.analytics.view_feature || ANALYTICS_VIEW_FEATURES.APPLY_BUTTON;

    setAnalyticsOrigin({
      originView: this.props.analytics.view,
      originViewFeature: viewFeature,
      originViewFeatureLocation: this.props.analytics.view_feature_location
    });

    this.props.onClick();
  });

  render() {
    const {
      breederProfileId,
      className,
      currentUser,
      isLoggedIn,
      truncateText,
      withIcon,
      customCopy
    } = this.props;

    const appliedTo = get(
      currentUser,
      'value.data.breeder_profiles_applied_to',
      []
    );
    const canApply =
      typeof isLoggedIn === 'boolean' && !appliedTo.includes(breederProfileId);

    if (!canApply) {
      return null;
    }

    const defaultCopy = (
      <>
        Apply&nbsp;
        <span className={classnames({ 'd-none d-sm-inline': truncateText })}>
          to breeder
        </span>
      </>
    );

    return (
      <Fragment>
        <button
          className={classnames('BreederProfileApplyButton', className)}
          onClick={this.handleApplyClick}
          type="button"
        >
          {withIcon && <Icon className="mr3" name="application" />}
          {customCopy ? <span>{customCopy}</span> : defaultCopy}
        </button>
      </Fragment>
    );
  }
}
